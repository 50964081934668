<template>
  <div class="page">
    
    <div class="content" >
          <div class="panel-left" v-html="$t('instruction-session')" >

          </div>
          <div class="panel-buttons">
              <app-button class="button"  color="purple" bevel="true" :caption="$t('button-start-session')"  @click="handleNew" />
              <app-button class="button"  color="purple" bevel="false" :caption="$t('button-restart-session')"  @click="handleResume" />
              <app-button class="button restartbutton"  color="purple" bevel="false" :caption="$t('button-prev')"  @click="handlePrev" />
          </div>
    </div>
    <password-popup ref="passwordpopup" @submit="handleResumeConfirm" />

  </div>
</template>

<script>
  import AppButton from  "@/components/ui/AppButton.vue";
  import PasswordPopup from  "@/components/ui/PasswordPopup.vue";

  export default {
    name: 'setup-session',
    components: {
      AppButton,
      PasswordPopup
    },
    data() {
      return {
      }
    },
    computed: {      

    },
    methods: {
      handleNew() {
          this.$store.dispatch('user/setResuming',false);
          this.$router.push('room');
      },
      handleResume() {
          if (process.env.NODE_ENV === "development") {
            this.$store.dispatch('user/setResuming',true);
            this.$router.push('room');
          }
          else {
            this.$refs.passwordpopup.show("resume");
          }        
      },
      handleResumeConfirm() {
          this.$store.dispatch('user/setResuming',true);
          this.$router.push('room');
      },
      handlePrev() {
          this.$router.push('/');
      }

    },
    mounted() {
      this.$store.dispatch('setFooterVisibility',false);      
    }
  }
</script>

<style scoped>

.page {
    position: relative;
    background-color: var(--purple-dark-color);
}

.content {
    position: absolute;
    top: 60px;
    width:100vw;
    height: 90vh;
    /* background-position: bottom; */
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: center; */
}

.panel-left {
  width: 73vw;
  height: 75vh;

  margin-top: 160px;
  margin-left:130px;
  
  font-family:'Fira Sans Bold';
  font-size: calc(48px / var(--scale));
  line-height: calc(68px / var(--scale));
  color: var(--yellow-dark-color);
}


.panel-buttons {
    height: 80vh;
    width:27vw;
    margin-top:180px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}


.panel-buttons .button {
  margin-bottom: 50px;
  margin-right:60px;
  margin-left: 50px;
}

.restartbutton {
  margin-top:20px;
}
 
</style>