<template>
    <transition name="fade">
        <div v-if="(errormessage != '')" :class="['errormessage',color]">{{errormessage}}</div>
    </transition>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: 'error-banner',
        props: ['color'],
        data() {
            return {
                errormessage: ''
            }
        },
        computed: {
            ...mapState( {
                popupmessages: state => state.popupmessages,
            }),
        },
        methods: {    
            clearError() {
                this.errormessage = '';
            },
            showError(id){
                this.errormessage = this.popupmessages[id];
            },
            showErrorString(str) {
                console.error(str)
                this.errormessage = str;
            }
        }
    };
</script>


<style scoped>

.errormessage {
  position: absolute;
  width:100vw;
  height: calc(42px / var(--scale));
  background-color: var(--purple-dark-color);
  font-family:'Fira Sans Medium';
  font-size: calc(20px / var(--scale));
  color: var(--light-text-color);
  box-shadow: 0px 3px 6px #00000029;
  padding:9px;
  padding-left: 55px;
}

.purple {
  background-color: var(--purple-dark-color);
  color: var(--light-text-color);
}

.yellow {
  background-color:  #7FD9F2;
  color: var(--dark-text-color);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
