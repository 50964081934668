<template>
  <div class="page">
    
    <div class="content" >
          <error-banner color="yellow" ref="errorbanner" />

          <div v-if="!this.$store.getters['user/isResuming']" class="panel-left">
                
            <div class="instruction">{{$t('instruction-groupname')}}</div>

            <textarea
              ref="groupname"
              class="inputfield"
              :maxlength="max_input_length"              
              :placeholder="placeholder"
              @input="handleInputChange">
            </textarea>
          </div>
          
          <div v-else class="panel-left-groupnames">
                
            <div v-for="(groupname,index) in this.groupnames" 
            :class="['groupname',(selected_groupname === groupname) ? 'selected':'']"
            :key="index" 
            @click="handleSelection(groupname)">
              {{ groupname }}
            </div>

          </div>
          <div class="panel-buttons">
              <app-button class="button" ref="nextbutton" color="purple" bevel="true" :caption="$t('button-next')"  @click="handleSubmit" />
              <app-button class="button" ref="prevbutton" color="purple" bevel="false" :caption="$t('button-prev')"  @click="handlePrev" />
          </div>
      </div>

  </div>
</template>

<script>

  import axios from 'axios'

  import AppButton from  "@/components/ui/AppButton.vue";
  import ErrorBanner from  "@/components/ui/ErrorBanner.vue";

  //import SocketClientHelper from "@/helpers/SocketClientHelper"


  export default {
    data() {
      return {
        groupnames: [],
        selected_groupname : null,
      }
    },
    created () {
        this.max_input_length = 36;
        this.placeholder = 'naam1, naam2, naam3';
    },
    computed: {
    
    },
    components: {
      AppButton,
      ErrorBanner
    },
    methods: {
      handleInputChange( {target}) {
        //this.errormessage = '';
        // get current element
        let v = target.value;
        this.selected_groupname = v;
        
        let input_ok = (v.length > 0 && v.length < this.max_input_length) ;

        if (v.length >= this.max_input_length ) {
           this.$refs.errorbanner.showError("input-too-long");
        }
        else {
          this.$refs.errorbanner.clearError();
        }
        this.$refs.nextbutton.enabled( input_ok );
      },
      handleSubmit() {        
       // console.log("resuming:" + this.$store.getters['user/isResuming'] )    
        if (this.$store.getters['user/isResuming']) {
          this.$store.dispatch("user/resumeGroup",this.selected_groupname);
          //this.$store.commit('user/SET_GROUP_NAME',this.selected_groupname)
          this.handleSubmitComplete();
        }
        else {
          //console.log("save groupname:" + this.selected_groupname)
          this.$store.dispatch('user/setGroupname',this.selected_groupname)
            .then(() => {
              this.handleSubmitComplete();
            })
            .catch(error => {
               this.$refs.errorbanner.showErrorString(error);
            });
        }        
      }, 
      handleSubmitComplete() {
        console.log("status: " + this.$store.getters["game/status"])
          switch(this.$store.getters["game/status"]){
            case "setup": {
              this.$router.push('/student/instructions');
              break;
            }
            case "started": {
              console.log("START from setup groupname")
              // BVB 28 02 2023 prevent error
              // NavigationDuplicated: Avoided redundant navigation to current location
              // this occurs because this socket event listener exists in multiple locations, which is clearly
              // not a good idea
              // I left the listeners as is and just check if we are already at the route path
              // A better option would be to centralize the socket listeners, but that would be a 
              // substantial rewrite
              if (this.$route.path !='/student/map/start' ){
                this.$router.push('/student/map/start')
              }
              break;
            }
            case "endgame": {
              // BVB 28 02 2023 prevent error
              // NavigationDuplicated: Avoided redundant navigation to current location
              // this occurs because this socket event listener exists in multiple locations, which is clearly
              // not a good idea
              // I left the listeners as is and just check if we are already at the route path
              // A better option would be to centralize the socket listeners, but that would be a 
              // substantial rewrite
              if (this.$route.path !='/student/endgame/instructions' ){
                this.$router.push('/student/endgame/instructions')
              }              
              break;
            }
            case "finished": {
              this.$router.push('/student/endgame/shield');
              break;
            }
          }
      },
      handlePrev() {
          //this.$emit("prev");
          this.$router.push('room');
      },
      handleSelection(groupname) {
        this.selected_groupname = groupname;
        this.$refs.nextbutton.enabled( true );
      }
    },
    mounted() {

      if (this.$store.getters['user/isResuming']) {
        let endpoint = process.env.VUE_APP_API + this.$store.getters["user/roomname"] + "/groups"

        axios
        .get(endpoint)
        .then(result => {
          // console.log("save groups")
            //console.log(result.data.groupNames)
            this.groupnames = result.data.groupNames;
        })
        .catch(error => {
          console.log(error)
        })
      }


      this.$refs.nextbutton.enabled(false);
      this.$store.dispatch('setFooterVisibility',false);      

      //console.log( this.$store.getters.groups )
    }
  }
</script>

<style scoped>
  
.page {
   background-color: var(--green-middle-color);
    display: flex;
    /* flex-direction: row; */
    justify-content: center; 
    align-items: flex-start;
}


.content {
    position: relative;
    margin-top: 60px;
    width:100vw;
    height: 90vh;
    /* background-position: bottom; */
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: center; */
}

.panel-left {
  width: 73vw;
  height: 75vh;

  margin-top: 115px;
  margin-left:150px;
}

.panel-left-groupnames {
  width: 73vw;
  height: 50vh;

  /* background-color: green; */

  margin-top: 110px;
  margin-left:50px;

  /* height: 500px;
  width: 500px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}


.groupname {

  width: calc(393px / var(--scale));
  height: calc(51px / var(--scale));
  line-height: calc(51px / var(--scale));
  background-color: var(--purple-light-color);
  font-family:'Fira Sans SemiBold';
  font-size: calc(19px / var(--scale));
  padding: 0 20px;
  /* margin: 4px; */
  margin-right: 40px;
  margin-top: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.groupname.selected {
  color: var(--purple-dark-color);
}

.panel-buttons {
    height: 80vh;
    width:27vw;
    margin-top:160px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.instruction {
  font-family:'Fira Sans Bold';
  font-size: calc(35px / var(--scale));
  color: var(--yellow-dark-color);
  margin:10px 0;
}
.inputfield {
    resize: none;
    height: calc(55px / var(--scale));
    width: calc(610px / var(--scale));
    /* padding: 0 5px; */
    font-family: 'Fira Sans SemiBold';
    font-size: calc(30px / var(--scale));
    line-height: calc(40px / var(--scale));
    /* font-weight: bold; */
    margin-right: 10px;
    padding:6px 10px;
}

.panel-buttons .button {
  margin-bottom: 50px;
  margin-right:60px;
  margin-left: 50px;
}


</style>